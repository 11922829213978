import Vue from 'vue'
import Router from 'vue-router'
import store from "./store"

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: () => import('@/views/dashboard/Index'),
      redirect : "/admin/login",
      children: [
        {
          name : 'Dashboard',
          path : '/super/dashboard',
          component : () => import('@/views/dashboard/DashboardAdmin'),
        },
        {
          name : 'Customers',
          path : '/super/customers',
          component : () => import('@/views/dashboard/pages/supa/Customers'),
        },
        {
          name : 'Companies',
          path : '/super/companies',
          component : () => import('@/views/dashboard/pages/supa/Companies'),
        },
        {
          name : 'Settings',
          path : '/super/settings',
          component : () => import('@/views/dashboard/pages/supa/Settings'),
        },
        {
          name : 'Servers',
          path : '/super/devices/servers/:id',
          component : () => import('@/views/dashboard/pages/supa/Servers'),
        },
        {
          name : 'Sboxes',
          path : '/super/devices/sboxes/:id',
          component : () => import('@/views/dashboard/pages/supa/Sboxes'),
        },
        {
          name : 'Dashboard',
          path : '/admin/dashboard',
          component : () => import('@/views/dashboard/Dashboard'),
        },
        {
          name : 'SubDashboard',
          path : '/admin/sub/dashboard',
          component : () => import('@/views/dashboard/SubDashboard'),
        },
        {
          name : 'SubLogs',
          path : '/admin/sub/logs',
          component : () => import('@/views/dashboard/pages/admin/SubLogs'),
        },
        {
          name : 'Users',
          path : '/admin/users',
          component : () => import('@/views/dashboard/pages/admin/Users'),
        },
        {
          name : 'Guests',
          path : '/admin/guests',
          component : () => import('@/views/dashboard/pages/admin/Guests'),
        },
        {
          name : 'Admins',
          path : '/admin/admins',
          component : () => import('@/views/dashboard/pages/admin/Admins'),
        },
        {
          name : 'Doors',
          path : '/admin/doors',
          component : () => import('@/views/dashboard/pages/admin/Doors'),
        },
        {
          name : 'Logs',
          path : '/admin/logs',
          component : () => import('@/views/dashboard/pages/admin/Logs'),
        },
        {
          name : 'Devices',
          path : '/admin/devices',
          component : () => import('@/views/dashboard/pages/admin/Devices'),
        },
        {
          name : 'Settings',
          path : '/admin/settings',
          component : () => import('@/views/dashboard/pages/admin/Settings'),
        },
        {
          name : 'TemplatePermission',
          path : '/admin/templatepermission',
          component : () => import('@/views/dashboard/pages/admin/TemplatePermission'),
        },
        {
          name : 'SubGuests',
          path : '/admin/sub/guests',
          component : () => import('@/views/dashboard/pages/admin/SubGuests'),
        },
        {
          name : 'SubDoors',
          path : '/admin/sub/doors',
          component : () => import('@/views/dashboard/pages/admin/SubDoors'),
        },
        {
          name : 'SubSettings',
          path : '/admin/sub/settings',
          component : () => import('@/views/dashboard/pages/admin/SubSettings'),
        },
        {
          name : 'ResetPassword',
          path : '/reset_password',
          component : () => import('@/views/dashboard/pages/supa/ResetAdminPassword'),
        },
        {
          path: '/admin',
          component: () => import('@/views/dashboard/Index'),
          redirect : "/admin/login",
        },
        {
          path: '/super',
          component: () => import('@/views/dashboard/Index'),
          redirect : "/super/login",
        },
        {
          path: '/user',
          component: () => import('@/views/dashboard/Index'),
          redirect : "/user/login",
        },
        {
          path : '/user/settings/reset_password',
          component : () => import('@/views/dashboard/pages/user/ResetPassword'),
        },

        {
          path: '/user/doors',
          component: () => import('@/views/dashboard/pages/user/DoorsUser'),
        },
        {
          path: '/user/settings',
          component: () => import('@/views/dashboard/pages/user/Settings'),
        },
        {
          path: '/guest/qrcode',
          component: () => import('@/views/dashboard/pages/guest/QrCodeGenerate'),
        },
        {
          path: '/user/settings/reset_password',
          component: () => import('@/views/dashboard/pages/user/ResetPassword'),
        },
      ]
    },

    
    // {
    //   path: '/',
    //   component: () => import('@/views/dashboard/Index'),
    //   redirect: '/login',
    //   children: [
    //     // Dashboard
    //     /*{
    //       name: '',
    //       path: 'dashboard',
    //       component: () => import('@/views/dashboard/Dashboard'),
    //     }, */
    //     // Pages
       
    //   ]
    // },
    
    {
      path: '/admin/login',
      component: () => import('@/views/dashboard/pages/admin/Login'),
    },
    {
      path: '/super/login',
      component: () => import('@/views/dashboard/pages/supa/LoginAdmin'),
    },
    {
      path: '/user/login',
      component: () => import('@/views/dashboard/pages/user/LoginUser'),
    },
    
    {
      path: '/admin/exit',
      component: () => import('@/views/dashboard/pages/admin/Logout'),
    },
    {
      path: '/admin/sub/exit',
      component: () => import('@/views/dashboard/pages/admin/Logout'),
    },
    {
      path: '/super/exit',
      component: () => import('@/views/dashboard/pages/supa/LogoutAdmin'),
    },
    {
      path: '/user/exit',
      component: () => import('@/views/dashboard/pages/user/Logout'),
    },
    /*
    {
      path: '/admin',
      component: () => import('@/views/dashboard/pages/LoginAdmin'),
    },
    {
      path: '/soon',
      component: () => import('@/views/dashboard/pages/soon'),
    }, */
    
    /*

    {
      path: '/*',
      component: () => import('@/views/dashboard/Index'),
      redirect: '/dashboard',
    }, */

  ],
})



