import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios"
import { router } from "./router"

Vue.use(Vuex)


const store = new Vuex.Store({
  state : {

      token : "",
      barColor: '#fe3c00',
      // barImage: 'https://demos.creative-tim.com/material-dashboard/assets/img/sidebar-1.jpg',
      drawer: null,
      //token: null, did not work well
  },
  mutations : {

      SET_BAR_IMAGE (state, payload) {
        state.barImage = payload
      },
      SET_DRAWER (state, payload) {
        state.drawer = payload
      },
      SET_TOKEN (state, payload) {
        state.token = payload
      },
  },
  actions : {
      // initAuth({ commit, dispatch}){
      //    let token = localStorage.getItem("token")
      //     if(token){

      //         let expirationDate = localStorage.getItem("expirationDate")
      //         let time = new Date().getTime()

      //         if(time >= +expirationDate){
      //             console.log("token süresi geçmiş...")
      //             dispatch("logout")
      //         } else {
      //             commit("setToken", token)
      //             let timerSecond = +expirationDate - time
      //             console.log(timerSecond)
      //             dispatch("setTimeoutTimer", timerSecond)
      //             router.push("/")
      //         }

      //     } else {
      //         router.push("/auth")
      //         return false
      //     }
      // },
      // verify_token({ commit, dispatch}){
      //   console.log(localStorage.token);
      //   if (localStorage.token != "" && localStorage.token != "undefined" && localStorage.token != null) {
      //      // console.log(localStorage.token);
      //      let token = localStorage.token;
      //      let form_data = {};
      //      form_data.token = token;

      //      axios.post('http://api.doorsapp.com.tr:8081/mock-api/verify-token',form_data).then((response) => {
           
      //      if (response.status === 200) {
      //         console.log(response);
      //         if (response.data.resp == "1") {
      //            console.log("redirecting to dashboard");
      //           //  this.$router.push({path: '/dashboard/?t='+token});
      //           commit("setToken", token)
      //           router.push("/dashboard" + token)
                 
      //         }
      //         if (response.data.resp == "0") {
      //            localStorage.token = ""
      //         }
      //      }
      //      }).catch(error => {
      //         // console.log("error")
      //         if(error.response.status = 401)
      //         {
      //            localStorage.token = ""
      //         }
      //         router.push("/login")
             
      //      })
      //   }
      // },
      // login({ commit, dispatch, state}, authData){
      //     let authLink = 'http://api.doorsapp.com.tr:8081/mock-api/login';
      //     console.log(authLink);
      //     console.log(authData.email);
      //     console.log(authData.password);
      //     return axios.post(
      //         authLink,
      //         { email :authData.email, pass : authData.password }
      //     ).then(response => {
      //         console.log(response)
      //         commit("setToken", response.data.token)
      //         localStorage.setItem("token", response.data.token)
              
      //         // localStorage.setItem("expirationDate", new Date().getTime() + +response.data.expiresIn * 1000)
      //         // localStorage.setItem("expirationDate", new Date().getTime() + 10000)

      //         // dispatch("setTimeoutTimer", +response.data.expiresIn * 1000)
              
      //     })
      //     .catch(error => {
      //     console.log(error)
      //       if(error.response.data.code = 401)
      //       {
      //         // console.log("empty data was sent");
      //         // this.show_username_pass_error(); 
      //       }
      //    })
      // },
      // logout({ commit}){
      //     commit("clearToken")
      //     localStorage.removeItem("token")
      //     localStorage.removeItem("expirationDate")
      //     router.replace("/auth")
      // },
      // setTimeoutTimer({dispatch}, expiresIn){
      //     setTimeout(() => {
      //         dispatch("logout")
      //     }, expiresIn)
      // }
  },
  // getters : {
  //     isAuthenticated(state){
  //       // this.verify_token()
  //         return state.token !== ""
  //     }
  // }
})

export default store





// -----------------------------------------------------------------------------


// export default new Vuex.Store({
//   state: {
//     barColor: '#fe3c00',
//     //barImage: 'https://demos.creative-tim.com/material-dashboard/assets/img/sidebar-1.jpg',
//     drawer: null,
//     //token: null, did not work well
//   },
//   mutations: {
//     SET_BAR_IMAGE (state, payload) {
//       state.barImage = payload
//     },
//     SET_DRAWER (state, payload) {
//       state.drawer = payload
//     },
//     SET_TOKEN (state, payload) {
//       state.token = payload
//     },
//   },
//   actions: {

//   },
// })
