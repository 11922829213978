// =========================================================
// * Vuetify Material Dashboard - v2.1.0
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vuetify-material-dashboard
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/base'
import './plugins/chartist'
import './plugins/vee-validate'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import {Howl, Howler} from 'howler';
import VueSweetalert2 from 'vue-sweetalert2';
import VueToast from 'vue-toast-notification';
// import jwt from 'jsonwebtoken';
import JsonExcel from "vue-json-excel";
import Vuelidate from 'vuelidate'
window.$ = window.jQuery = require('jquery')
import VuePhoneNumberInput from 'vue-phone-number-input';
import ToggleButton from 'vue-js-toggle-button'
import VueCurrencyInput from 'vue-currency-input'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import HistogramSlider from "vue-histogram-slider";
import "vue-histogram-slider/dist/histogram-slider.css";
import moment from 'moment-timezone'
import SmoothPicker from 'vue-smooth-picker'
import 'vue-smooth-picker/dist/css/style.css'

Vue.use(SmoothPicker)

import 'vue-scroll-picker/lib/style.css'
//axios here //
import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)


// axios.defaults.withCredentials = true;
// axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
//axios here //

// Vue.use(Notifications);
Vue.use(ToggleButton)
Vue.use(VueSweetalert2);
Vue.use(VueToast);
Vue.use(Vuelidate);
Vue.use(VueCurrencyInput);
Vue.use(BootstrapVue)

Vue.config.productionTip = false

Vue.component(HistogramSlider.name, HistogramSlider);
Vue.component("downloadExcel", JsonExcel);
Vue.component('vue-phone-number-input', VuePhoneNumberInput);


Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app')
